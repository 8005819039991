<template>
  <div class="members-directory">
    <ApiErrorMessage :message="apiErrorMessage" />
    <div class="panel-inr-box">
      <div class="box-top-wrap d-flex align-center justify-space-between">
        <div class="item-row">
          <button @click="$router.push({ name: 'BookLog' })" size="25" class="btn customBtn pattern-btn white--text">
            <span> {{ $t("marketingCampaign.back") }}</span>
          </button>
          <h3 class="box-title green-text">
            {{
              $route.params && $route.params.vehicleId
                ? $t("bookLog.updateVehicleTitle")
                : $t("bookLog.addVehicleTitle")
            }}
          </h3>
        </div>
      </div>
      <div class="custom-card vehicle-car">
        <div class="custom-box">
          <v-form class="addVehicle-form">
            <div class="form-row">
              <div class="form-col form-col-half">
                <label class="form-label">{{ $t('bookLog.control') }}</label>
                <v-text-field hide-details="auto" height="45"
                  :placeholder="$t('bookLog.control')" v-model="data.control" solo dense></v-text-field>
              </div>
              <div class="form-col form-col-half">
                <label class="form-label">{{ $t('bookLog.vin') }}</label>
                <v-text-field maxlength="17" hide-details="auto" height="45"
                  :placeholder="$t('bookLog.vin')" v-model="data.vin" :loading="vinLoading" @input="
                    getVinSearchData(data.vin);
                  $v.data.vin.$touch();
                  vinError = [];
                  " @blur="$v.data.vin.$touch()" :error-messages="vinErrors" solo dense></v-text-field>
                <v-progress-linear v-if="vinLoading" indeterminate color="green"></v-progress-linear>
              </div>
              <div class="form-col form-col-half">
                <label class="form-label">{{ $t('bookLog.engineNo') }}</label>
                <v-text-field hide-details="auto" height="45"
                  :placeholder="$t('bookLog.engineNo')" v-model="data.engine_number" solo dense></v-text-field>
              </div>
              <div class="form-col form-col-half">
                <label class="form-label">{{ $t('bookLog.make') }}</label>
                <v-text-field v-model="data.make" height="45" hide-details="auto"
                  :placeholder="$t('bookLog.make')" solo dense></v-text-field>
              </div>
              <div class="form-col form-col-half">
                <label class="form-label">{{ $t('bookLog.model') }}</label>
                <v-text-field v-model="data.model"  hide-details="auto"
                  :placeholder="$t('bookLog.model')" height="45" solo dense></v-text-field>
              </div>
              <div class="form-col form-col-half">
                <label class="form-label">{{ $t('bookLog.year') }}</label>
                <v-text-field solo v-model="data.year"  hide-details="auto"
                  :placeholder="$t('bookLog.year')" height="45" dense></v-text-field>
              </div>
              <div class="form-col form-col-half">
                <label class="form-label">{{ $t('bookLog.trim') }}</label>
                <v-text-field solo v-model="data.trim" hide-details="auto"
                  :placeholder="$t('bookLog.trim')" height="45" dense></v-text-field>
              </div>
              <div class="form-col form-col-half">
                <label class="form-label">{{ $t('bookLog.body') }}</label>
                <v-select solo class="selectVehicleInfo" :attach="true" :items="bodyList" v-model="data.body"
                 hide-details="auto" :placeholder="$t('bookLog.body')" height="45"
                  dense></v-select>
              </div>
              <div class="form-col form-col-half">
                <label class="form-label">{{ $t('bookLog.style') }}</label>
                <v-select solo class="selectVehicleInfo" :attach="true" v-model="data.style" :items="styleList"
                   hide-details="auto" :placeholder="$t('bookLog.style')" height="45"
                  dense></v-select>
              </div>
              <div class="form-col form-col-half">
                <label class="form-label">{{ $t('bookLog.color') }}</label>
                <v-select solo class="selectVehicleInfo" :attach="true" :items="colorList" v-model="data.color"
                   hide-details="auto" :placeholder="$t('bookLog.color')" height="45"
                  dense></v-select>
              </div>
              <div class="form-col form-col-half">
                <label class="form-label">{{ $t('bookLog.mileage') }}</label>
                <v-text-field hide-details="auto" v-model="data.mileage" 
                  :placeholder="$t('bookLog.mileage')" solo height="45" dense></v-text-field>
              </div>
              <div class="form-col form-col-half">
                <label class="form-label">{{ $t('bookLog.purchaseSource') }}</label>
                <v-text-field hide-details="auto" v-model="data.purchase_source" 
                  :placeholder="$t('bookLog.purchaseSource')" solo height="45" dense></v-text-field>
              </div>
              <div class="form-col form-col-half">
                <label class="form-label">{{ $t('bookLog.sourceContactPerson') }}</label>
                <v-text-field hide-details="auto" v-model="data.source_contact_person"
                  :placeholder="$t('bookLog.sourceContactPerson')" solo
                  height="45" dense></v-text-field>
              </div>
              <div class="form-col form-col-half">
                <label class="form-label">{{ $t('bookLog.sourceTelNumber') }}</label>
                <v-text-field hide-details="auto" v-model="data.source_tel_no" 
                  :placeholder="$t('bookLog.sourceTelNumber')" v-to-us-format solo height="45" dense></v-text-field>
              </div>
              <div class="form-col form-col-half">
                <label class="form-label">{{ $t('bookLog.address') }}</label>
                <GooglePlace id="address" :isRequired="false" placeholder="bookLog.address" :address="data.source_address"
                  validationMsg="bookLog.validations.sourceAddressIsRequired" @onAddressSelect="addressHandler"
                  height="45" solo dense />
              </div>
              <div class="form-col form-col-half">
                <label class="form-label">{{ $t('bookLog.state') }}</label>
                <v-text-field solo v-model="data.state" hide-details="auto"
                  :placeholder="$t('bookLog.state')" height="45" dense></v-text-field>
              </div>
              <div class="form-col form-col-half">
                <label class="form-label">{{ $t('bookLog.storeLocation') }}</label>
                <v-text-field hide-details="auto" 
                  :placeholder="$t('bookLog.storeLocation')" v-model="data.store_location" solo height="45"
                  dense></v-text-field>
              </div>
              <div class="form-col form-col-half">
                <label class="form-label">{{ $t('bookLog.acquiredDate') }}</label>
                <v-menu :close-on-content-click="false" transition="scale-transition" offset-y v-model="menu2"
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field solo v-model="acquiredDate" hide-details="auto"
                      :placeholder="$t('bookLog.acquiredDate')" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                      height="45" dense></v-text-field>
                  </template>
                  <v-date-picker color="green darken-4" v-model="data.acquired_date" @input="menu2 = false">
                  </v-date-picker>
                </v-menu>
              </div>
              <div class="form-col form-col-half">
                <label class="form-label">{{ $t('bookLog.purchaseCost') }}</label>
                <v-text-field value="" id="purchasecostv" v-model="data.purchasecost" hide-details="auto"
                   :placeholder="$t('bookLog.purchaseCost')"
                  append-icon="mdi-currency-usd" @input="convertUSCurrency()" @keypress="validCost($event)" solo
                  height="45" dense></v-text-field>
              </div>
              <div class="form-col form-col-half">
                <label class="form-label">{{ $t('bookLog.purchaseDate') }}</label>
                <v-menu v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                  min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field solo v-model="purchasedDate" hide-details="auto"
                      :placeholder="$t('bookLog.purchaseDate')" append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                      height="45" dense></v-text-field>
                  </template>
                  <v-date-picker color="green darken-4" v-model="data.purchase_date" @input="menu = false">
                  </v-date-picker>
                </v-menu>
              </div>
              <div class="form-col form-col-half">
                <label class="form-label">{{ $t('bookLog.licensePlate') }}</label>
                <v-text-field hide-details="auto" v-model="data.licence_plate"
                  :placeholder="$t('bookLog.licensePlate')" solo height="45" dense></v-text-field>
              </div>
              <div class="form-col form-col-half">
                <label class="form-label">{{ $t('bookLog.expirationDate') }}</label>
                <v-menu :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto"
                  v-model="menu3">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field solo hide-details="auto" :placeholder="$t('bookLog.expirationDate')"
                      append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" v-model="expirationDate" height="45"
                      dense></v-text-field>
                  </template>
                  <v-date-picker color="green darken-4" v-model="data.expiration_date" @input="menu3 = false">>
                  </v-date-picker>
                </v-menu>
              </div>
              <div class="form-col">
                <label class="form-label">{{ $t("bookLog.uploadImageTitle") }}</label>
                <div class="flex
                              w-full
                              h-screen
                              items-center
                              justify-center
                              text-center
                              fileuploads rd-fileUpload
                            " id="app">
                  <v-progress-circular v-if="fileLoader" indeterminate :size="40" color="green"></v-progress-circular>
                  <div class="" @dragover="dragover" @drop="drop" v-if="!fileLoader">
                    <input type="file" multiple name="file" id="assetsFieldHandle" @change="onChange" ref="file"
                      accept=".jpg,.jpeg,.png" />
                    <label for="assetsFieldHandle" class="fileuploadlabel">
                      {{ $t("bookLog.uploadImageTitle") }}
                    </label>
                    <ul class="img-preview-list-wrapper" v-if="filelist.length">
                      <li class="img-preview-list" v-for="(file, index) in filelist" :key="index">
                        <div class="img-preview-list-img-wrap">
                          <img :src="file.data.url" width="50px" />
                          <span class="img-preview-list-helper">
                            <!-- {{ file.data && file.data.name}} -->
                            <a type="button" @click="
                              remove(
                                filelist.indexOf(file),
                                file.data
                              )
                              " title="Remove file"><v-icon>mdi-delete</v-icon></a>
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="form-col">
                <v-btn hide-details="auto"
                  class="btn customBtn pattern-btn white--text text-capitalize btn-submit font-16" @click="postBookLog" :loading="saving" :disabled="($v.data.$invalid && $v.data.$anyDirty) ||
                    vinError.length > 0 ||
                    saving
                    ">
                 <span> {{ $t("bookLog.save") }}
                </span>
                  <template v-slot:loading>
                    <v-icon>mdi-loading</v-icon>
                  </template>
                </v-btn>
              </div>
            </div>
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";
import numberUtility from "@/utils/number.utility";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    GooglePlace: () => import("@/components/common/GooglePlace"),
    ApiErrorMessage: () => import("@/components/common/ApiErrorMessage"),
  },

  data() {
    return {
      vinLoading: false,
      fileLoader: false,
      menu: false,
      menu2: false,
      menu3: false,
      loading: false,
      saving: false,
      address: null,
      userDetail: {},
      list: [],
      modelList: [],
      yearList: [],
      trimList: [],
      vinError: [],
      apiErrorMessage: null,
      validAddress: true,
      filelist: [],
      bodyList: ["2D", "2P", "4D", "5H", "CP", "PK", "SV", "UT"],
      styleList: [
        "4 doors",
        "Coupe",
        "Hardtop 2 doors",
        "Hatchback 2 doors",
        "Hatchback 5 doors",
        "Pick up",
        "SUV",
        "Van",
      ],
      colorList: [
        "Black",
        "Blue",
        "Brown",
        "Gold",
        "Gray",
        "Green",
        "Orange",
        "Pink",
        "Purple",
        "Red",
        "Silver",
        "Voilet",
        "White",
        "Yellow",
      ],
      initialize: true,
      data: {
        control: null,
        make: null,
        year: null,
        model: null,
        trim: null,
        engine_number: null,
        purchasecost: "",
        source_contact_person: null,
        source_tel_no: null,
        body: null,
        source_address: null,
        color: null,
        state: null,
        style: null,
        vin: null,
        mileage: null,
        purchase_source: null,
        licence_plate: null,
        store_location: null,
        media_ids: [],
        purchase_date: moment().format("yyyy-MM-DD"),
        acquired_date: moment().format("yyyy-MM-DD"),
        expiration_date: moment().format("yyyy-MM-DD"),
      },
    };
  },

  validations: {
    data: {
      vin: { required, minLength: minLength(17) },
    },
  },

  computed: {
    ...mapGetters({
      imageResponse: "bookLog/getImageResponse",
      makeOptions: "bookLog/getMake",
      modelOptions: "bookLog/getModel",
      yearOptions: "bookLog/getYear",
      trimOptions: "bookLog/getTrim",
      bookLogDetail: "bookLog/getBookLogById",
      vinSearch: "bookLog/getVinSearchData",
    }),

    expirationDate() {
      return this.data.expiration_date
        ? moment(this.data.expiration_date).format("MM/DD/YY")
        : "";
    },
    acquiredDate() {
      return this.data.acquired_date
        ? moment(this.data.acquired_date).format("MM/DD/YY")
        : "";
    },
    purchasedDate() {
      return this.data.purchase_date
        ? moment(this.data.purchase_date).format("MM/DD/YY")
        : "";
    },

    vinErrors() {
      const vinErrors = [];
      if (!this.$v.data.vin.$dirty) return vinErrors;
      if (this.vinError && this.vinError.length) {
        return this.vinError;
      }
      !this.$v.data.vin.required &&
        vinErrors.push(this.$t("bookLog.validations.vinIsRequired"));
      !this.$v.data.vin.minLength &&
        vinErrors.push(this.$t("bookLog.validations.vinLength"));
      return vinErrors;
    },
  },

  created() {
    if (this.$route.params.vehicleId) {
      this.initialize = true;
      this.id = Number(this.$route.params.vehicleId);
      this.getBookLogById(this.id);
    }
  },

  mounted() {
    if (this.user && this.user.user_detail) {
      this.userDetail = this.user.user_detail;
      this.userDetail.representative_phone = numberUtility.toUsPhone(
        this.userDetail.representative_phone
      );
      this.userDetail.company_phone = numberUtility.toUsPhone(
        this.userDetail.company_phone
      );
    }
  },

  methods: {
    ...mapActions({
      uploadImage: "bookLog/uploadImage",
      deleteImage: "bookLog/deleteImage",
      getMake: "bookLog/getMake",
      getModel: "bookLog/getModel",
      getYear: "bookLog/getYear",
      getTrim: "bookLog/getTrim",
      saveBookLog: "bookLog/saveBookLog",
      bookLogById: "bookLog/getBookLogById",
      saveBookLogById: "bookLog/saveBookLogById",
      getVinSearch: "bookLog/vinSearch",
    }),

    validCost(e) {
      const validate = this.data.purchasecost.split(".");
      if (validate[1] && validate[1].length >= 2) {
        e.preventDefault();
      }
      const reg = /[^0-9/.]/;
      if (reg.test(e.key)) {
        e.preventDefault();
      }
    },

    convertUSCurrency() {
      let currency = Number(
        this.data.purchasecost.toString().replace(/[^0-9/.]/g, "")
      )
        .toString()
        .split(".");
      if (!currency[1]) {
        this.data.purchasecost = currency[0]
          .replace(/\D/g, "")
          .split("")
          .reverse()
          .reduce(function (acc, num, i) {
            return num == "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
          }, "");
      } else {
        this.data.purchasecost =
          currency[0]
            .replace(/\D/g, "")
            .split("")
            .reverse()
            .reduce(function (acc, num, i) {
              return num == "-" ? acc : num + (i && !(i % 3) ? "," : "") + acc;
            }, "") +
          "." +
          currency[1];
      }
    },

    async getBookLogById(id) {
      try {
        await this.bookLogById(id);
        this.data = Object.assign({}, this.bookLogDetail);
        const medias = this.data.medias.map((item) => {
          return { data: item };
        });
        this.filelist = [...this.filelist, ...medias];
        //console.log(this.filelist);
        this.data.media_ids = this.filelist.map((val) => val.data.id);
        this.convertUSCurrency();
      } catch ({ message }) {
        this.apiErrorMessage = message;
      }
    },

    async getVinSearchData(data) {
      try {
        if (data.length === 17) {
          this.vinLoading = true;
          await this.getVinSearch({ vin: data });
          this.data.engine_number = this.vinSearch.engine_id;
          this.data.model = this.vinSearch.model;
          this.data.make = this.vinSearch.make;
          this.data.year = this.vinSearch.year;
          this.data.trim = this.vinSearch.trim;
        } else {
          this.data.engine_number = "";
          this.data.model = "";
          this.data.make = "";
          this.data.year = "";
          this.data.trim = "";
        }
      } catch ({ message }) {
        this.vinError = message;
      } finally {
        this.vinLoading = false;
      }
    },

    async changeMakeOption() {
      this.data.model = null;
      this.data.year = null;
      this.data.trim = null;
      this.modelList = [];
      this.yearList = [];
      this.trimList = [];
      try {
        await this.getModel({
          make: this.data.make,
        });
        this.modelList = [...this.modelOptions];
        if (this.$route.params.vehicleId && this.initialize) {
          this.data.model = this.bookLogDetail.model;
          await this.changeModelOption();
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      }
    },

    async changeModelOption() {
      this.data.year = null;
      this.data.trim = null;
      this.yearList = [];
      this.trimList = [];
      try {
        await this.getYear({
          model: this.data.model,
        });
        this.yearList = await [...this.yearOptions];
        if (this.$route.params.vehicleId && this.initialize) {
          this.data.year = this.bookLogDetail.year;
          await this.changeYearOption();
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      }
    },

    async changeYearOption() {
      this.data.trim = null;
      this.trimList = [];
      try {
        await this.getTrim({
          year: this.data.year,
        });
        this.trimList = [...this.trimOptions];
        if (this.$route.params.vehicleId && this.initialize) {
          this.data.trim = this.bookLogDetail.trim;
          this.initialize = false;
        }
      } catch ({ message }) {
        this.apiErrorMessage = message;
      }
    },

    async getMakeOptions() {
      try {
        await this.getMake();
      } catch ({ message }) {
        this.apiErrorMessage = message;
      }
    },

    async postBookLog() {
      this.$v.data.$touch();
      if (!this.$v.data.$invalid) {
        try {
          this.saving = true;
          if (this.$route.params.vehicleId) {
            await this.saveBookLogById(this.data);
          } else {
            await this.saveBookLog(this.data);
          }
          this.$router.push({ name: "BookLog" });
        } catch ({ message }) {
          this.apiErrorMessage = message;
        } finally {
          this.saving = false;
        }
      }
    },

    addressHandler(payload) {
      this.data.source_address = payload.address;
      this.data.state = payload.state;
    },

    async onChange() {
      try {
        this.$refs.file.files.forEach((val, i) => {
          if (
            this.$refs.file.files[i].type === "image/jpeg" ||
            this.$refs.file.files[i].type === "image/png"
          ) {
            this.saveImage(this.$refs.file.files[i]);
          }
        });
      } catch ({ message }) {
        this.apiErrorMessage = message;
      }
    },

    async remove(i, file) {
      try {
        this.fileLoader = true;
        await this.deleteImage(file.id);
        await this.filelist.splice(i, 1);
        this.data.media_ids = this.filelist.map((val) => val.data.id);
      } catch ({ message }) {
        this.apiErrorMessage = message;
      } finally {
        this.fileLoader = false;
      }
    },

    async saveImage(file) {
      try {
        this.fileLoader = true;
        let formData = new FormData();
        await formData.append("file", file);
        await this.uploadImage(formData);
        this.fileLoader = false;
        this.filelist = [...this.filelist, this.imageResponse];
        this.data.media_ids = this.filelist.map((val) => val.data.id);
      } catch ({ message }) {
        this.apiErrorMessage = message;
      }
    },

    dragover(event) {
      event.preventDefault();
    },

    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
    },
  },
};
</script>
